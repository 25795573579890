import Vue from 'vue'
import Router from 'vue-router'
import { FORM_METADATA } from '@/constants/fields'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      meta: { auth: true },
      path: '/home',
      name: 'Home',
      component: () => import('@/pages/Home'),
    },
    {
      meta: { layout: 'no-navigation', auth: true },
      path: '/user-without-job-board',
      name: 'User Without Team',
      component: () => import('@/pages/UserWithoutTeam'),
    },
    {
      meta: {
        auth: false,
        layout: 'no-navigation',
      },
      path: '/signin',
      name: 'Sign In',
      component: () => import('@/pages/Auth'),
      props: { type: 'sign-in' },
    },
    {
      meta: {
        auth: false,
        layout: 'no-navigation',
      },
      path: '/start-trial',
      name: 'Start Trial',
      component: () => import('@/pages/StartTrial'),
    },
    {
      meta: {
        auth: false,
        layout: 'no-navigation',
      },
      path: '/forgot-password',
      name: 'Forgot Password',
      component: () => import('@/pages/Auth'),
      props: { type: 'forgot-password' },
    },
    {
      meta: {
        auth: false,
        layout: 'no-navigation',
      },
      path: '/reset-password',
      name: 'Reset Password',
      component: () => import('@/pages/Auth'),
      props: { type: 'reset-password' },
    },
    {
      meta: { auth: true },
      path: '/financials',
      name: 'Financials',
      component: () => import('@/pages/Financial'),
      redirect: '/financials/orders',
      children: [
        {
          path: 'orders',
          name: 'Orders',
          component: () => import('@/pages/Financial/Orders'),
        },
        {
          path: 'order/:orderNumber',
          name: 'Order',
          component: () => import('@/pages/Financial/Order'),
          meta: {
            breadCrumb: [
              {
                text: 'Orders',
                to: { name: 'Orders' },
              },
            ],
          },
        },
        {
          path: 'subscriptions',
          name: 'Subscriptions',
          component: () => import('@/pages/Financial/Subscriptions'),
        },
        {
          path: 'subscription/:subscriptionNumber',
          name: 'Subscription',
          component: () => import('@/pages/Financial/Subscription'),
          meta: {
            breadCrumb: [
              {
                text: 'Subscriptions',
                to: { name: 'Subscriptions' },
              },
            ],
          },
        },
      ],
    },
    {
      meta: { auth: true },
      path: '/jobs',
      name: 'Jobs',
      component: () => import('@/pages/Jobs'),
    },
    {
      meta: { auth: true },
      path: '/jobs/:jobId',
      name: 'Edit Job',
      component: () => import('@/pages/Jobs'),
      props: true,
      children: [
        {
          path: 'job',
          name: 'Edit Job',
          component: () => import('@/pages/Jobs/Edit.vue'),
        },
        {
          path: 'subscribers',
          name: 'Job Subscribers',
          component: () => import('@/pages/Jobs/Subscribers.vue'),
        },
      ],
    },
    {
      meta: { auth: true },
      path: '/analytics',
      name: 'Analytics',
      component: () => import('@/pages/Report'),
      children: [
        {
          path: '',
          name: 'Job analytics',
          component: () => import('@/pages/Report/JobCandidate.vue'),
        },
        {
          path: 'jobs',
          name: 'Job analytics',
          component: () => import('@/pages/Report/JobCandidate.vue'),
        },
        {
          path: 'companies',
          name: 'Company analytics',
          component: () => import('@/pages/Report/EmployerEngagement.vue'),
        },
        {
          path: 'users',
          name: 'User analytics',
          component: () => import('@/pages/Report/UserBehavior.vue'),
        },
      ],
    },
    {
      meta: { auth: true },
      path: '/companies',
      name: 'Company',
      component: () => import('@/pages/Company'),
    },
    {
      meta: { auth: true },
      path: '/companies/:companyId',
      name: 'Edit Company',
      component: () => import('@/pages/Company'),
      props: true,
      children: [
        {
          path: 'team',
          name: 'Company Team',
          component: () => import('@/pages/Company/Team.vue'),
        },
        {
          path: 'profile',
          name: 'Edit Company',
          component: () => import('@/pages/Company/Profile.vue'),
        },
      ],
    },
    {
      meta: { auth: true },
      path: '/users',
      name: 'User',
      component: () => import('@/pages/User'),
    },
    {
      meta: { auth: true },
      path: '/users/:userId',
      name: 'Edit User',
      component: () => import('@/pages/User'),
      props: true,
    },
    {
      meta: { auth: true },
      path: '/applications',
      name: 'Applications manager',
      component: () => import('@/pages/Applications'),
    },
    {
      meta: { auth: true },
      path: '/applications/:id',
      name: 'View applicant',
      component: () => import('@/pages/Applications'),
      props: true,
    },
    {
      meta: { auth: true },
      path: '/alerts',
      name: 'Job alerts manager',
      component: () => import('@/pages/JobAlerts'),
    },
    {
      meta: { auth: true },
      path: '/alerts/:alertId',
      name: 'Edit job alert',
      component: () => import('@/pages/JobAlerts'),
      props: true,
    },
    {
      meta: { auth: true },
      path: '/account',
      name: 'Account',
      component: () => import('@/pages/Account'),
    },
    {
      meta: {
        layout: 'no-navigation',
      },
      path: '/invitation',
      name: 'Invitation',
      component: () => import('@/pages/Invitation'),
    },
    {
      meta: { auth: true },
      path: '/preferences',
      name: 'Preferences',
      component: () => import('@/pages/Preferences'),
      children: [
        {
          meta: {
            auth: true,
          },
          name: 'AI',
          path: 'ai',
          component: () => import('@/pages/Preferences/AI'),
        },
        {
          meta: {
            auth: true,
          },
          name: 'Billing',
          path: 'billing',
          component: () => import('@/pages/Preferences/Billing'),
        },
        {
          meta: {
            auth: true,
          },
          name: 'Team',
          path: 'team',
          component: () => import('@/pages/Preferences/Team/Team'),
        },
        {
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Team',
                to: { name: 'Team' },
              },
            ],
          },
          name: 'Team Roles',
          path: 'team/roles',
          component: () => import('@/pages/Preferences/Team/Roles'),
        },
        {
          meta: {
            auth: true,

            breadCrumb: [
              {
                text: 'Team',
                to: { name: 'Team' },
              },

              {
                text: 'Roles',
                to: { name: 'Team Roles' },
              },
            ],
          },
          path: 'team/roles/:id',
          component: () => import('@/pages/Preferences/Team/RolesEdit'),
          props: true,
        },
        {
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Team',
                to: { name: 'Team' },
              },
            ],
          },
          path: 'team/users',
          name: 'Team Users',
          component: () => import('@/pages/Preferences/Team/Users'),
        },
        {
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Team',
                to: { name: 'Team' },
              },

              {
                text: 'Users',
                to: { name: 'Team Users' },
              },
            ],
          },
          path: 'team/users/:id',
          component: () => import('@/pages/Preferences/Team/UsersEdit'),
          props: true,
        },
        {
          meta: { auth: true },
          path: 'appearance',
          component: () => import('@/pages/Preferences/Appearance'),
        },
        {
          meta: { auth: true },
          path: 'products',
          name: 'Products',
          component: () => import('@/pages/Preferences/Monetization/Products'),
        },
        {
          meta: { auth: true },
          path: 'upsells',
          name: 'Upsells',
          component: () => import('@/pages/Preferences/Monetization/Upsells'),
        },
        {
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Products',
                to: { name: 'Products' },
              },
            ],
          },
          path: 'products/:productId',
          name: 'Edit Product',
          component: () => import('@/pages/Preferences/Products/Product'),
          props: true,
        },
        {
          meta: { auth: true },
          path: 'promotion-code',
          name: 'Promotion Code',
          component: () =>
            import('@/pages/Preferences/Monetization/PromotionCode'),
          props: true,
        },
        {
          meta: { auth: true },
          path: 'promotion-code/:promotionCodeId',
          name: 'Edit Promotion Code',
          component: () => import('@/pages/Preferences/Products/PromotionCode'),
          props: true,
        },
        {
          meta: {
            auth: true,
            breadCrumb() {
              return [
                {
                  text: 'Upsells',
                  to: { name: 'Upsells' },
                },
              ]
            },
          },
          path: 'upsells/:id',
          name: 'Upsells',
          component: () => import('@/pages/Preferences/Upsells/UpsellsEdit'),
          props: true,
        },
        {
          meta: { auth: true },
          path: 'general',
          name: 'General',
          component: () => import('@/pages/Preferences/General'),
        },
        {
          meta: {
            auth: true,
          },
          path: 'fields',
          name: 'Fields Menu',
          component: () => import('@/pages/Preferences/Fields/Fields'),
        },
        {
          meta: {
            auth: true,
            breadCrumb() {
              return [
                {
                  text: 'Fields',
                  to: { name: 'Fields Menu' },
                },
              ]
            },
          },
          path: 'fields/:formName',
          name: 'Fields',
          component: () => import('@/pages/Preferences/Fields/EditFields'),
          props: true,
        },
        {
          meta: {
            auth: true,
            breadCrumb(route) {
              return [
                {
                  text: 'Fields',
                  to: { name: 'Fields Menu' },
                },
                {
                  text: FORM_METADATA[route.params.formName].name,
                  to: {
                    name: 'Fields',
                    params: { formName: route.params.formName },
                  },
                },
              ]
            },
          },
          path: 'fields/:formName/:formId/:fieldId',
          name: 'Edit Field',
          component: () => import('@/pages/Preferences/Fields/EditField'),
          props: true,
        },
        {
          meta: { auth: true },
          path: 'code',
          name: 'Custom code',
          component: () => import('@/pages/Preferences/CustomCode'),
        },
        {
          meta: { auth: true },
          path: 'categories',
          name: 'Categories',
          component: () => import('@/pages/Preferences/CategoriesAndTags'),
        },
        {
          meta: { auth: true },
          path: 'legal',
          name: 'Legal',
          component: () => import('@/pages/Preferences/Legal'),
        },
        {
          meta: { auth: true },
          path: 'alerts',
          name: 'Job alerts',
          component: () => import('@/pages/Preferences/JobAlerts'),
        },
        {
          meta: { auth: true },
          path: 'localization',
          name: 'Localization',
          component: () => import('@/pages/Preferences/Localization'),
        },
        {
          meta: { auth: true },
          path: 'backfill',
          name: 'Backfilled Jobs',
          component: () => import('@/pages/Preferences/Backfill'),
        },
        {
          path: 'aggregation-rules',
          name: 'Aggregation Rules',
          component: () =>
            import('@/pages/Preferences/Backfill/AggregationRules'),
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Backfilled jobs',
                to: { name: 'Backfilled Jobs' },
              },
            ],
          },
        },
        {
          path: 'aggregation-filters-mapping',
          name: 'Aggregation Filters Mapping',
          component: () =>
            import('@/pages/Preferences/Backfill/AggregationFiltersMapping'),
          meta: {
            auth: true,
            breadCrumb: [
              {
                text: 'Backfilled jobs',
                to: { name: 'Backfilled Jobs' },
              },
            ],
          },
        },
        {
          meta: { auth: true },
          path: 'identity',
          name: 'OAuth',
          component: () => import('@/pages/Preferences/OAuth'),
        },
        {
          meta: { auth: true },
          path: 'talent-network',
          name: 'TalentNetwork',
          component: () =>
            import('@/pages/Preferences/TalentNetwork/TalentNetwork'),
        },
      ],
    },
    {
      meta: {
        auth: true,
        layout: 'no-navigation',
      },
      path: '/connect-stripe',
      name: 'ConnectStripe',
      component: () => import('@/pages/ConnectStripe'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  },
})
